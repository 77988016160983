<template>
    <div>
        <!-- CAROUSEL-->
    <b-row>
      <b-col cols="12" >
        <div  class="back-home-title">
         
          <b-row align-h="center">
            <b-col class="mt-5">
              <b-img style="min-width:250px; max-width:250px;" :src="require('../assets/Susyfinalmente.png')"  ></b-img>
            </b-col>
            <b-col class="mt-3 mb-3" cols="11">
              <h2 style="color:#A98307;" class="p-text-l">Real Estate Consulting & Yoga Professional</h2>
            </b-col>
            <b-col cols="11" sm="11" md="10" lg="8" xl="8" class="mt-3 mb-5">
              <p style="color:purple;" class="p-text">Hi, my name is Susi MacDonald, I'm a Real Estate professional who also loves to share the practice of yoga as an instructor.</p>
              <p style="color:purple;" class="p-text">The intention in the creation of this platform is to represent myself as an individual, not a product.   To offer my set of skills and expertise in these fields and be able to help people with them.</p>
              <p style="color:purple;" class="p-text">I have lived in Mexico for more than Ten years working the Cancun/Riviera Maya Real Estate consistently during that time. I know the market extremely well and can guide and assist clients in every step of the purchasing process. I am fully fluent in English and Spanish.</p>
              <p style="color:purple;" class="p-text">Yoga came into my life 20 years ago. I have taken numerous teacher trainings and certifications which allows me to target and adjust to my students needs.</p>
            </b-col>
          </b-row>
        </div>
        
      </b-col>
      
    </b-row>
    <!-- <b-row align-h="center">
      <b-col cols="11" sm="11" md="10" lg="8" xl="8" class="mt-3 mb-5">
        <p class="p-text">Hi, I'm Susy C. Macdonald, Real Estate expert and Yoga teacher.</p>
        <p class="p-text">My vision when creating this platform is to represent myself as a person, not as an offer of a product. Share with you my skills and help people with them.</p>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col class="back-home-body2">
          <b-row align-h="center">
            <b-col cols="11" sm="11" md="10" lg="8" xl="8" class="p-text mt-5">
              <p style="color:purple;"  class="p-text">I see myself as a human being in a constant state of evolution and growth.  At the same time preserving that essence that characterizes me.</p>
              <p style="color:purple;" class="p-text">I love to discover new places and experiences.  Is a way to connect with other cultures and ways of thinking.  Always evolving and growing personally and professionally.</p>
              <p style="color:purple;" class="p-text">My goal in everything I do is for it to be for the benefit not only of myself but also others because every action has a reaction and I want my impact in the world to be as positive as I can make it.</p>
              <p style="color:purple;" class="p-text">I work on always being present and aware of everything I do.  To live in the here and now.  The world is full of opportunities, each with it's own unique set of challenges but as we learn to travel those new paths we can grow and find new ones.</p>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col cols="11" sm="11" md="10" lg="8" xl="8" class="mt-5 mb-5">
              <p style="color:purple;" class="p-text">Perseverance is something that characterizes me in a unique way.   No matter how difficult a situation I never give up.</p>
              <p style="color:purple;" class="p-text">Helping is paramount to me.</p>
            </b-col>
          </b-row>
          <b-row align="center" align-h="center">
            <b-col cols="11" sm="10" md="5" lg="2" xl="2" class="mb-5">
              <div>
                <b-img style="max-width:250px;" :src="require('../assets/susiefoto.jpeg')"  ></b-img>
              </div>
            </b-col>
          </b-row>
          <!-- <b-row align-h="center">
            <b-col cols="11" sm="6" md="4" lg="3" xl="3" class="mt-5 mb-5" >
              <div>
                <b-img :src="require('../assets/b1.jpeg')" fluid ></b-img>
              </div>  
            </b-col>
            <b-col cols="11" sm="6" md="4" lg="3" xl="3" class="mt-5 mb-5">
              <div>
                <b-img :src="require('../assets/b2.png')" fluid ></b-img>
              </div>  
            </b-col>
            <b-col cols="11" sm="6" md="4" lg="3" xl="3" class="mt-5 mb-5">
              <div>
                <b-img :src="require('../assets/b3.jpeg')" fluid ></b-img>
              </div>  
            </b-col>
          </b-row> -->
      </b-col>
    </b-row>
    
    <b-row align="center">
      <b-col class="back-home-body">
        <b-row>
          <b-col class="real-text mt-5 mb-4">
            <b style="font-size:50px;">Let’s stay in touch</b><br>
            <b style="font-size:50px;">Write your name in the sand.</b>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="10" sm="8" md="4" lg="3" xl="3">
            <b-row align-h="center">
              <b-col class="mt-4" cols="12"><b-img style="height:190px" :src="require('../assets/Susyfinalmente.png')" fluid ></b-img></b-col>
              <b-col class="mb-4 p-text mt-4" cols="12">
                <h6>Contact</h6>
                <h6>info@susimacdonald.com</h6>
                <h6>+52 984 167 4506</h6>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="11" sm="11" md="8" lg="5" xl="3" class="mt-4 mb-4 " >
            <b-row align-h="left" align="left">
              <div  style="background-color:transparent; padding:17px;">
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input" label="Name" type="text" validation="" v-model="form.name"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input" label="Email" type="email" validation="email" v-model="form.email"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input" label="Phone" type="text" validation="" v-model="form.phone"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input-c"  label="Interest" type="checkbox" label-position="before" validation="" :options="{RealEstate: 'Real Estate', Yoga: 'Yoga' }"  v-model="form.interest"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input" label="Comment" type="textarea" validation="" v-model="form.comment"/></b-col>      
                <b-col cols="12"><b-button style="color:rgba(255,255,255,125); radius-border:10px;" @click="enviarMail">Send</b-button></b-col> 
              </div>    

              <form  ref="form" >
                <input v-show="false" id="name" name="name" type="text"  v-model="form.name"> 
                <input v-show="false" id="email" name="email" type="text"  v-model="form.email"> 
                <input v-show="false" id="phone" name="phone" type="text"  v-model="form.phone"> 
                <input v-show="false" id="interest" name="interest" type="text"  v-model="form.interest"> 
                <input v-show="false" id="comment" name="comment" type="text"  v-model="form.comment"> 
              </form>
              
            </b-row>
          </b-col>
        </b-row>
        <b-row align-h="center" class="back-home-body-b" >
         
          <b-col cols="11" md="10" lg="8" xl="7"  class="mb-5" style="height:580px; ">
                  <div style="height:100%; width: 100%; margin-top:25px;">
                    <b-embed
                      class="c-shadow-sm"
                      style="width:100%; height:580px;"
                      type="iframe"
                      src="https://www.youtube.com/embed/xIADwhtIXm0"
                    ></b-embed>
                  </div>
                </b-col>
        </b-row>
        <b-row align-h="center" align-v="center" align="center" class="mb-4" >
          <b-col class="mt-4" cols="11" md="6" lg="3" xl="3"><b-img style="height:100%; width:100%;" :src="require('../assets/colaboracion1.png')"  ></b-img></b-col>
          <b-col class="mt-4" cols="11" md="6" lg="3" xl="3" ><b-img style="height:100%; width:100%;" :src="require('../assets/colaboracion2.png')"  ></b-img></b-col>
          <b-col class="mt-4" cols="11" md="6" lg="3" xl="3"><b-img style="height:100%; width:100%;" :src="require('../assets/colaboracion3.png')"  ></b-img></b-col>
          <b-col class="mt-4" cols="11" md="6" lg="3" xl="3"><b-img style="height:100%; width:auto;" :src="require('../assets/colaboracion4.png')"  ></b-img></b-col>
        </b-row>
      </b-col>

    </b-row>
    
  </div>
</template>
<script>
  import { mapState} from 'vuex'
  import emailjs from '@emailjs/browser';

  export default {
    components: {
    },
    computed: {
      ...mapState({
        
      }),
    },
    data() {
      return {
        form:{
          name:"",
          email:"",
          phone:"",
          interest:"Real State",
          comment:""
        }
      }
    },
    methods: {
      enviarMail(){
        emailjs.sendForm('service_6nsrtfm', 'template_m0kyzjk', this.$refs.form, 'W8YrTfKVZ2WwQY5A-')
        .then((result) => {
            alert('Gracias por tu registro!', result.text);
            this.form.name="";
            this.form.email="";
            this.form.phone = "";
            this.form.interest="Real Estate";
            this.form.comment = "";
        }, (error) => {
            alert('Tu registro no se completo. Intenta más tarde', error.text);
        });
      }
    },
  }
</script>
<style scoped>
@import url('../assets/snow.min.css'); 

.back-home-title{
  
  background-image: url('../assets/susyfondo1.jpg'); 
  background-repeat:no-repeat;
  background-size: 100% 100%;
}
.back-home-logo{
 
  background-image: url('../assets/Susyfinalmente.png'); 
  background-repeat:no-repeat;
  background-position: center;
  
}
.back-home-body{
  background-image: url('../assets/texturasusy.jpg'); 
  background-size: auto;
}
.back-home-body2{
  background-image: url('../assets/imagensol.jpg'); 
  background-size: 100% auto;
}


</style>